import React, { useState } from "react";
import { Box, Button, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import {
  useDeposit,
  useDeposit2,
  useBNBPrice1,
  useTokenLeft,
  useMagicPrice2,
} from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveFour, setActiveFour] = useState(false);
  const toggleClassFour = () => {
    setActiveFour(!isActiveFour);
  };
  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1 = useBNBPrice1(npool.earnContractAddress);
  const TL = useTokenLeft(npool.earnContractAddress);
  const MGP2 = useMagicPrice2(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem colSpan={[12, 12, 6, 4]}>
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Button
            className={isActiveFour ? "info_btn active" : "info_btn"}
            onClick={toggleClassFour}
          ></Button>
          <Box
            className={
              isActiveFour ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_04">
                <Box className="magic_blur_box">
                  <Image
                    src="/images/magik_ic_01.png"
                    alt=""
                    className="magik_ic_01"
                  />
                  <Heading as="h6">Price floor sweaper 2x NFT</Heading>
                  <Heading as="h5">
                    Minted {forMat(byDecimals(TL, 0).toNumber())} of 500
                  </Heading>
                  <Text>
                  The project will buy back all NFTs of the collection at 1.25x the purchase price using yield generated from staking the revenue gained from sales. When the project has accumulated enough yield to purchase an NFT, the NFT will be transferred directly from the holder's wallet and the funds will be transferred to the holders' wallet.
                  </Text>
                </Box>
                <Box className="mgc_blur_btm_box">
                  <Box className="green_brdr_bx">
                    <Text>
                      <Image
                        src="/images/magic_ic02.svg"
                        alt=""
                        className="magic_ic02"
                      />
                      1 NFT {forMat(byDecimals(MP1, 18))} ETH
                    </Text>
                    <Button
                      onFocus={(event) => event.stopPropagation()}
                      disabled={isDepositPending}
                      onClick={(event) => {
                        onDeposit(MP1);
                      }}
                    >
                      Buy
                    </Button>
                  </Box>
                  <Box className="green_brdr_bx">
                    <Text>
                      <Image
                        src="/images/magic_ic02.svg"
                        alt=""
                        className="magic_ic02"
                      />
                      3 NFTs {forMat(byDecimals(MGP2, 18).toNumber())} ETH
                    </Text>
                    <Button
                      onFocus={(event) => event.stopPropagation()}
                      disabled={isDepositPending2}
                      onClick={(event) => {
                        onDeposit2(MGP2);
                      }}
                    >
                      Buy
                    </Button>
                  </Box>
                  <span className="btm_smal_text">
                    Price increase after every sale
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClass}></Button> */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back same_nine_ractangle_inn_back_04">
                  <Image
                    src="/images/magic_logo.png"
                    alt=""
                    className="magic_logo"
                  />
                  <Box className="back_inn_p back_magic_p">
                    <Text>
                      Magic is a unique cross chain DeFi ecosystem project built
                      upon a modular open framework, whose codebase is future
                      proof, easily upgradeable, and continuously evolving.{" "}
                      <br />
                      <br /> <br />
                    </Text>
                    <Text>
                      As decentralized finance continues its technological
                      growth and evolution, so too will Defi Magic.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://bsc.defimagic.io"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                bsc.defimagic.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
