import React, { useState } from "react";
import {
  Box,
  Button,
  GridItem,
  Heading,
  Image,
  Text,

} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveNine, setActiveNine] = useState(false);
  const toggleClassNine = () => {
    setActiveNine(!isActiveNine);
  };

  const [isActiveAshv, setActiveAshv] = useState(false);
  const toggleClassAshv = () => {
    setActiveAshv(!isActiveAshv);
  };
  const [amount, setAmount] = useState(2);
  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );


  const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos="fade-up"
        data-aos-delay="2000"
        data-aos-duration="2000"
      >
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActiveAshv ? "info_btn_02 active" : "info_btn_02"}
            onClick={toggleClassAshv}
          ></Button>
          <Button
            className={isActiveNine ? "info_btn active" : "info_btn"}
            onClick={toggleClassNine}
          ></Button>
          <Box
            className={
              isActiveNine ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box
                className={
                  isActiveAshv
                    ? "same_nine_ractangle_inn_video activets"
                    : "same_nine_ractangle_inn_video"
                }
              >
                <Box className="blue_hole_video_prnt">
                  <video className="width_266_video_02" loop autoPlay muted>
                    <source
                      src="/images/bitgraphix_puzzle.webm"
                      type="video/webm"
                    />
                  </video>
                </Box>
              </Box>
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_09">
                <Box className="gloden_tiket_main_box">
                  <Box className="jigso_img_box">
                    <Image src="/images/jigso_img.png" alt="" />
                    <Heading as="h5">Golden Ticket</Heading>
                    <Text>
                      This NFT provieds entry to innaugural Jigsaw Puzzle
                      Internations Convention, as well as the following
                      benefits:
                    </Text>
                    <Text className="pregf_main_cls">
                      (5) PUZL tokens, to be realesed after the convention (1)
                      Loot Box NFT containing random digital Jigsaw puzzle
                      pieces
                    </Text>
                  </Box>
                  <Box className="nfts_firt_box">
    
                  <Button
                      onFocus={(event) => event.stopPropagation()}
                      disabled={isDepositPending}
                      onClick={(event) => {
                        onDeposit(MP1);
                      }}
                    >
                      1 NFT / {forMat(byDecimals(MP1),18)} ETH
                    </Button>
                    <Button
                      onFocus={(event) => event.stopPropagation()}
                      disabled={isDepositPending2}
                      onClick={(event) => {
                        onDeposit2(MP1 * 3);
                      }}
                    >
                    3 NFT / {forMat(byDecimals(MP1 * 3),18)} ETH
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back same_nine_ractangle_inn_back_09">
                  <Image
                    src="/images/jigso_img_03.png"
                    alt=""
                    className="jigso_img_03"
                  />
                  <Box className="back_inn_p back_magic_p_09">
                    <Text>
                      JPiC is on a mission to become the world’s only company
                      exlusively dedicated to all things related to the jigsaw
                      puzzle industry.We are the creators of the “Jigsaw Puzzle
                      International Convention” but also offer the following
                      services:
                    </Text>
                    <Text className="back_box_p">
                      JPiC Marketplace – The only marketplace in the world
                      exclusively dedicated to Jigsaw Puzzle Vendors.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button as="a" href="https://puzlnft.com" target="_blank" className="galla_img_links_first">
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                puzlnft.com
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic04.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic05.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic06.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
