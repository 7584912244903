import { Box, Link } from '@chakra-ui/react'
import React from 'react'
import { Button, Container, GridItem, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react'


export default function Footer() {
  return (
    <>
      <Box className='footer'>
        <Box className='footer_min'>
            <Image src='/images/footer_logo_img.png' alt='' className='footer_logo_img' /> 
            <Heading as='h3'>Contact Me</Heading>
            <Box className='foter_links'>
            <a href='mailto:ib@basterd.io'> <Image src='/images/foter_ic01.svg' alt='' className='foter_ic0' /> ib@basterd.io</a>
              <a href='https://bitgraphix.io/' target="_blank"  className='center_link'> <Image src='/images/foter_ic02.svg' alt='' className='foter_ic0' />www.bitgraphix.io</a>
              <a href='https://t.me/@the_inglourious_basterd' target="_blank"> <Image src='/images/foter_ic03.svg' alt='' className='foter_ic0' />@the_inglourious_basterd</a>
            </Box>
            {/* <Box className='foter_ic_min'>
              <Link href='#'><Image src='/images/footer_ic_01.svg' alt='' className='' /></Link>
              <Link href='#'><Image src='/images/footer_ic_02.svg' alt='' className='' /></Link>
              <Link href='#'><Image src='/images/footer_ic_03.svg' alt='' className='' /></Link>
              <Link href='#'><Image src='/images/footer_ic_04.svg' alt='' className='' /></Link>
            </Box> */}
            <Text>All Right Reserved Inglorious Basterd (c) 2022</Text>
          </Box>
      </Box>
    </>
  )
}
