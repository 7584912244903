import React, { useState } from "react";
import { Box, Button, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveThree, setActiveThree] = useState(false);
  const toggleClassThree = () => {
    setActiveThree(!isActiveThree);
  };

  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos-delay="1500"
        data-aos-duration="1500"
      >
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="1600"
        >
          <Button
            className={isActiveThree ? "info_btn active" : "info_btn"}
            onClick={toggleClassThree}
          ></Button>
          <Box
            className={
              isActiveThree ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_03">
                <Image
                  src="/images/pbgb_logo.png"
                  alt=""
                  className="pbgb_logo"
                />
                <Heading as="h6">Push Button Get Banana</Heading>
                <Box className="small_p_prnt">
                  <Text>
                    <Image
                      src="/images/small_p_ic.svg"
                      alt=""
                      className="small_p_ic"
                    />{" "}
                    10,000 Living NFTs on the Ethereum Blockchain
                  </Text>
                  <Text>
                    <Image
                      src="/images/small_p_ic.svg"
                      alt=""
                      className="small_p_ic"
                    />{" "}
                    Push that button. You know you want to
                  </Text>
                </Box>
                <Heading as="h3" className="center_head">
                  P<span>B</span>G<span>B</span> Plantains
                </Heading>
                <Heading as="h5">
                    Plantains are the larger, tougher cousin to bananas with a much thicker skin. If you have what it takes to become part of the inner circle of the Council of Basterds and see all the shenanigans we get up to, then you want to own a Plantain. You might need that thick skin.
                </Heading>
                <Box className="big_p_box">
                  <Text>
                    <Image src="/images/small_p_ic.svg" alt="" />
                    Access to private PBGB Council of Basterds TG
                  </Text>
                  <Text>
                    <Image src="/images/small_p_ic.svg" alt="" />
                    Access to the most heavily funded staking pools
                  </Text>
                  <Text>
                    <Image src="/images/small_p_ic.svg" alt="" />
                    Early access and info on projects and NFT launches
                  </Text>
                  <Text>
                    <Image src="/images/small_p_ic.svg" alt="" />
                    Direct access to team
                  </Text>
                </Box>
                <Box className="pbgb_btn_prnt">
                  <Button
                    className="plan1_btn"
                    onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending}
                    onClick={(event) => {
                      onDeposit(MP1[8]);
                    }}
                  >
                    <Image src="/images/pbgb_bnn_ic.svg" alt="" />1 Plantain
                  </Button>

                  <Button
                    onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending2}
                    onClick={(event) => {
                      onDeposit2(MP1[9]);
                    }}
                  >
                    <Image src="/images/pbgb_bnn_ic.svg" alt="" />5 Plantains
                  </Button>
                </Box>
                <Heading as="h2">
                  {forMat(byDecimals(MP1[8], 18))} ETH &nbsp;&nbsp;&nbsp;{" "}
                  {MP1[0]}/500
                </Heading>
              </Box>
            </Box>
            <Box className="flip-card-back">
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/pbgb_logo.png"
                    alt=""
                    className="pbgb_logo_back"
                  />
                  <Box className="back_inn_p back_pbgb_p">
                    <Text>
                      Push Button Get Banana
                      <br /> <br />
                    </Text>
                    <Text>
                      10,000 Living NFTs on the Ethereum &nbsp;&nbsp;Blockchain
                      <br />
                      <br />
                    </Text>
                    <Text>Push that button. You know you want to</Text>
                    <Heading as="h6">PBGB NFT?</Heading>
                    <Text>
                      Own an NFT that's worth a lot or absolutely nothing. This
                      is a project built by my wallet and for my wallet. It's in
                      my best interest to make that NFT worth more than you paid
                      for it so that you get excited and buy my next new thing!
                      Seriously, think about it. The reward has to be worth it,
                      right?
                    </Text>
                  </Box>
                </Box>
                {/* <Box className='same_nine_ractangle_inn_back'>
                                            <Image src='/images/liqud_craft_logo.png' alt='' className='liqud_craft_logo' /> 
                                            <Box className='back_inn_p liqud_craft_p'>
                                                <Heading as="h6">Collection Fine Liquid Crafts Has Never Been Easier</Heading>
                                                <Text>
                                                    iquid Craft is an NFT marketplace of fine spirits, wine, and beer aimed at bringing global producers and buyers together
                                                </Text>
                                                <Heading as="h6"> What is Liquid Craft?</Heading>
                                                <Text>
                                                    Liquid Craft is your one-stop shop to buy, sell, track, and trade Liquid Crafts
                                                </Text>
                                            </Box>
                                        </Box> */}
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://feedthemonkey.io"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />{" "}
                feedthemonkey.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
