import { Box, Button, Container, GridItem, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React, { Component } from 'react'
import { Link } from 'react-scroll';

export default function FirstSc() {
  return (
    <>
        <Box className='portfolio_first_section'>
            <Container maxW="container.xl" className="header_container">
                <SimpleGrid columns={12} columnGap={0} rowGap={0}>
                    <GridItem colSpan={[12, 12, 12, 6]} className='defi_lef_col'>
                        <Box className='defi_lef_col_agan' data-aos="zoom-in-down" data-aos-duration="1000">
                            <Heading as='h3'>Inglourious Basterd</Heading>
                            <Text>Dev, entrepreneur, project manager, systems architect, educator, and grumpy old bastard.</Text>
                            {/* <Button className='btn_box_gallary'>Gallery</Button> */}
                            <Link 
                                to='GallarySc'
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500} 
                                className='btn_box_gallary'>
                                    Gallery
                            </Link>
                        </Box>                        
                        <Image src='/images/btm_aro_defi.png' alt='' className='btm_aro_defi bounce' />
                    </GridItem>
                    <GridItem colSpan={[12, 12, 12, 6]} className='doble_imag_sat'>
                        <Box className='doble_imag_sat_main' data-aos="fade-up" data-aos-duration="1500">
                            <Image src='/images/defi_img_dun.png' alt='' className='defi_img_dun vert-move' />
                        </Box>
                    </GridItem>
                    
                </SimpleGrid>
            </Container>
        </Box>
    </>
  )
}
