import React, { useState } from "react";
import {
  Box,
  Button,
  GridItem,
  Heading,
  Image,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import {
  useDeposit,
  useDeposit2,
  useDeposit3,
  useBNBPrice1,
} from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveFour, setActiveFour] = useState(false);
  const [isActiveSix, setActiveSix] = useState(false);
  const toggleClassSix = () => {
    setActiveSix(!isActiveSix);
  };

  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit3, isPending: isDepositPending3 } = useDeposit3(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem colSpan={[12, 12, 6, 4]}>
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="2800"
        >
          <Button
            className={isActiveSix ? "info_btn active" : "info_btn"}
            onClick={toggleClassSix}
          ></Button>
          <Box
            className={
              isActiveSix ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_06">
                <Box className="liquid_blur_box">
                  <SimpleGrid columns={20} columnGap={0} rowGap={0}>
                    <GridItem colSpan={[7, 7, 7, 7]}>
                      <Box className="lqd_drum_img_prnt">
                        <Image
                          src="/images/lqd_drum_img.png"
                          alt=""
                          className="lqd_drum_img"
                        />
                      </Box>
                    </GridItem>
                    <GridItem colSpan={[13, 13, 13, 13]}>
                      <Box className="lqd_btn_box_prnt">
                        <Box className="lqd_btn_box">
                          <Image
                            src="/images/liquid_ic.svg"
                            alt=""
                            className="liquid_ic"
                          />
                          <Text>
                            <span>1 NFT</span> {forMat(byDecimals(MP1),18)} ETH
                          </Text>
                          <Button
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending}
                            onClick={(event) => {
                              onDeposit(MP1);
                            }}
                          >
                            Buy
                          </Button>
                        </Box>
                        <Box className="lqd_btn_box">
                          <Image
                            src="/images/liquid_ic.svg"
                            alt=""
                            className="liquid_ic"
                          />
                          <Text>
                          <span>2 NFT</span> {forMat(byDecimals(MP1 * 2),18)} ETH
                          </Text>
                          <Button
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending2}
                            onClick={(event) => {
                              onDeposit2(MP1 * 2);
                            }}
                          >
                            Buy
                          </Button>
                        </Box>
                        <Box className="lqd_btn_box">
                          <Image
                            src="/images/liquid_ic.svg"
                            alt=""
                            className="liquid_ic"
                          />
                          <Text>
                          <span>5 NFT</span> {forMat(byDecimals(MP1 * 5),18)} ETH
                          </Text>
                          <Button
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending3}
                            onClick={(event) => {
                              onDeposit3(MP1 * 5 );
                            }}
                          >
                            Buy
                          </Button>
                        </Box>
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </Box>
                <Box className="liquid_blur_btm_box">
                  <Text>
                    Have you ever dreamed of having your very own private line
                    of liquor like George Clooney? <br />
                    <br />
                  </Text>
                  <Text>
                    You can now turn this dream into a reality with the Special
                    Limited Edition Trinity Absinthe Ballel NFT Series from
                    Liquid Craft.
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClass}></Button> */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/liqud_craft_logo.png"
                    alt=""
                    className="liqud_craft_logo"
                  />
                  <Box className="back_inn_p liqud_craft_p">
                    <Heading as="h6">
                      Collection Fine Liquid Crafts Has Never Been Easier
                    </Heading>
                    <Text>
                      iquid Craft is an NFT marketplace of fine spirits, wine,
                      and beer aimed at bringing global producers and buyers
                      together
                    </Text>
                    <Heading as="h6"> What is Liquid Craft?</Heading>
                    <Text>
                      Liquid Craft is your one-stop shop to buy, sell, track,
                      and trade Liquid Crafts
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button as="a" href="https://liquidcraft.io" target="_blank" className="galla_img_links_first">
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                liquidcraft.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
