import React, { useState } from "react";
import { Box, Button, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveAs, setActiveAs] = useState(false);
  const toggleClassAs = () => {
    setActiveAs(!isActiveAs);
  };

  const [isActiveSeven, setActiveSeven] = useState(false);
  const toggleClassSeven = () => {
    setActiveSeven(!isActiveSeven);
  };

  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="500"
      >
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActiveAs ? "info_btn_02 active" : "info_btn_02"}
            onClick={toggleClassAs}
          ></Button>
          <Button
            className={isActiveSeven ? "info_btn active" : "info_btn"}
            onClick={toggleClassSeven}
          ></Button>
          <Box
            className={
              isActiveSeven ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box
                className={
                  isActiveAs
                    ? "same_nine_ractangle_inn_video activets"
                    : "same_nine_ractangle_inn_video"
                }
              >
                <Box className="blue_hole_video_prnt">
                  <video className="width_266_video" loop autoPlay muted>
                    <source
                      src="/images/blow_blue_video.webm"
                      type="video/webm"
                    />
                  </video>
                </Box>
              </Box>
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_07">
                <Box className="bitgraf_blar_bx">
                  <Box className="bit_ble_innr">
                    <Heading as="h2">
                      The first official Bitgraphix NFT! - Limited NFT Miami
                      Edition -
                    </Heading>
                    <Text>
                      Bitgraphix is the premium content creation company
                      servicing the blockchain space. This is the first NFT in
                      an upcoming series available exclusivly for NFT Miami.{" "}
                    </Text>
                    <Heading as="h6">
                      total mint: <span>{MP1[0]} / 100</span>
                    </Heading>
                  </Box>
                  <Box className="my_nft_two_btn">
                    <Box className="nft_buy_01">
                      <Text>
                        <Image
                          src="/images/nft_btn_img.png"
                          alt=""
                          className="nft_btn_img"
                        />
                        1 NFT -{" "}
                        <span>{forMat(byDecimals(MP1[8]), 18)} ETH</span>
                      </Text>
                      <Button
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending}
                        onClick={(event) => {
                          onDeposit(MP1[8]);
                        }}
                      >
                        Buy NFT
                      </Button>
                    </Box>
                    <Box className="nft_buy_01">
                      <Text>
                        <Image
                          src="/images/nft_btn_img.png"
                          alt=""
                          className="nft_btn_img"
                        />
                        3 NFT -{" "}
                        <span>{forMat(byDecimals(MP1[8] * 3), 18)} ETH</span>
                      </Text>
                      <Button
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending2}
                        onClick={(event) => {
                          onDeposit2(MP1[8] * 3);
                        }}
                      >
                        Buy NFT
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back same_nine_ractangle_inn_back_07">
                  <Image
                    src="/images/btgrfic_bg_back_img.png"
                    alt=""
                    className="btgrfic_bg_back_img"
                  />
                  <Box className="back_inn_p back_magic_p my_back_txt">
                    <Text>
                      Premium visual content and world class blockchain
                      engineering. <br /> <br />
                    </Text>
                    <Text>
                      Bitgraphix is a full spectrum creative and technical
                      partner; offering web design and construction, smart
                      contract authoring, web 3 integration, NFT design and full
                      service on-going dev support.
                      <br /> <br />
                    </Text>
                    <Text>
                      This special limited edition NFT is the birth of the
                      Bitgraphix NFT collection. <br /> <br />
                    </Text>
                    <Text>We are Crypto Rocket Fuel.</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://bsc.defimagic.io"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                bsc.defimagic.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
