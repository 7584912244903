import { VaultPage } from '.';


export default {
  path: "nft5",
  childRoutes: [
    { path: "nft5", component: VaultPage, isIndex: true },

  ],
};
