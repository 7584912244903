import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CircleBoxes7() {
  let { nft6pool } = useFetchPoolBalances();

  return (
    <>
      {nft6pool.map((npool, index) => (
          <PoolContent index={index} npool={npool} />
        ))}
    </>
  );
}

export default CircleBoxes7;
