export const nft3pool = [

  { 
    chainId: 3,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0x7E98779BD991296034D3d3c0f77Fc9693CC8424C',  // Golden ticket
    status: 'aktive'
  },
]