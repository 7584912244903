import React, { useState } from "react";
import { Box, Button, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveEight, setActiveEight] = useState(false);
  const toggleClassEight = () => {
    setActiveEight(!isActiveEight);
  };

  const [isActiveAsh, setActiveAsh] = useState(false);
  const toggleClassAsh = () => {
    setActiveAsh(!isActiveAsh);
  };
  const [amount, setAmount] = useState(1);

  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos="fade-up"
        data-aos-delay="1000"
        data-aos-duration="1000"
      >
        {/* <Box className='cntmt_info_btn_prnt cntmt_info_btn_prn_view'>
                            <Button className={isActive ? 'view_btn active': 'view_btn'} onClick={toggleClass}></Button>
                            <Button className={isActive ? 'view_btn active': 'view_btn'} onClick={toggleClass}></Button>
                        </Box> */}
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActiveAsh ? "info_btn_02 active" : "info_btn_02"}
            onClick={toggleClassAsh}
          ></Button>
          <Button
            className={isActiveEight ? "info_btn active" : "info_btn"}
            onClick={toggleClassEight}
          ></Button>
          {/* <Button className={isActive ? 'info_btn active': 'info_btn'} onClick={toggleClass}></Button> */}
          <Box
            className={
              isActiveEight ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box
                className={
                  isActiveAsh
                    ? "same_nine_ractangle_inn_video activets"
                    : "same_nine_ractangle_inn_video"
                }
              >
                <Box className="blue_hole_video_prnt">
                  <video className="width_266_video" loop autoPlay muted>
                    <source src="/images/pi_video.mp4" type="video/webm" />
                  </video>
                </Box>
              </Box>
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn08">
                <Box className="blr_box">
                  <Box className="hp_pad_box">
                    <Heading as="h2">
                      P<b>!</b> <span>NFT</span>
                    </Heading>
                    <Text>
                      Pi Circle NFTs and Pi Treasure hunt NFTs are beautiful
                      works of digital art that can be used to earn passive
                      income within the Pi-Protocol Ecosystem.
                    </Text>
                    <Text>
                      Utility Ticket: Total mint: <span>{MP1[0]} / 315</span>
                    </Text>
                  </Box>

                  <Box className="teresr_two_btn">
                    <Box className="buy_btn_min_box buy_min_box">
                      <Image
                        src="/images/buy_nft_btn_img.png"
                        alt=""
                        className="buy_nft_btn_img"
                      />
                      <Heading as="h2">
                        1 NFT <span>{forMat(byDecimals(MP1[8]), 18)} ETH</span>
                      </Heading>
                      <Button
                        className="buy_nft_btn"
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending}
                        onClick={(event) => {
                          onDeposit(MP1[8]);
                        }}
                      >
                        buy nft
                      </Button>
                    </Box>
                    <Box className="buy_btn_min_box">
                      <Image
                        src="/images/buy_nft_btn_img.png"
                        alt=""
                        className="buy_nft_btn_img"
                      />
                      <Heading as="h2">
                        3 NFTS{" "}
                        <span>{forMat(byDecimals(MP1[8] * 3), 18)} ETH</span>
                      </Heading>
                      <Button
                        className="buy_nft_btn"
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending2}
                        onClick={(event) => {
                          onDeposit2(MP1[8] * 3);
                        }}
                      >
                        buy nft
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClass}></Button>  */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/tres_back_img.png"
                    alt=""
                    className="tres_back_img"
                  />
                  <Box className="back_inn_p">
                    <Text>
                      PI is a Defi project built on BSC, that includes yield
                      farming for NFTs on NFT aggregator Vaults, extended by a
                      price floor sweeper mechanic! <br /> <br />
                    </Text>
                    <Text>
                      A full service provider for NFT Utility, we make Artist
                      dreams come true! <br />
                      <br />
                    </Text>
                    <Text>
                      Wrap your LPs to a token Wrapper, which is an NFT called
                      Circle.<br></br>
                      This Circle will be the entry Token for the NFT Vaults, as
                      well as all others whitelisted Partner NFT's.
                      <br />
                      Circles are collateral NFT's within the PI Ecosystem.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://pi-protocol.io"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                pi-protocol.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic04.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
