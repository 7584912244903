import React, { useState } from "react";
import { Box, Button, GridItem, Image, Text } from "@chakra-ui/react";

export default function Gallary1() {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isActiveTwo, setActiveTwo] = useState(false);
  const toggleClassTwo = () => {
    setActiveTwo(!isActiveTwo);
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos-delay="500"
        data-aos-duration="500"
      >
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActive ? "info_btn active" : "info_btn"}
            onClick={toggleClass}
          ></Button>
          {/* <Button className={isActiveAs ? 'info_btn_02 activeted': 'info_btn_02'} onClick={toggleClassAs}></Button>
                            <Box className={isActiveAs ? 'video_flipper activeed': 'video_flipper'}> */}
          <Box
            className={isActive ? "flip-card-inner active" : "flip-card-inner"}
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn">
                <Image
                  src="/images/defe_drageon_img_00.png"
                  alt=""
                  className="defe_drageon_img_00"
                />
                <Image
                  src="/images/defe_drageon_img_01.png"
                  alt=""
                  className="defe_drageon_img_01"
                />
                <Image
                  src="/images/defe_drageon_img_02.png"
                  alt=""
                  className="defe_drageon_img_02"
                />
                {/* <Image src='/images/defi_drageon.png' alt='' className='defi_drageon' /> */}
              </Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClass}></Button> */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/defi_text_back.png"
                    alt=""
                    className="defe_drageon_img_00"
                  />
                  <Box className="back_inn_p">
                    <Text>
                      A NEW WAY TO DeFi
                      <br />
                      Immerse yourself in the massive online fantasy world of
                      DeFi Dungeon! Stake your claim with one of two factions,
                      and gain power and wealth in a virtual economy that
                      includes crafting, farming, foraging, and more. Buy your
                      Council Seat NFTs now to earn rewards from the treasury
                      and participate in building the world of Defi Dungeon.
                      <br />
                      <br />
                      Coming Soon!
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box className='video_flipper_back'>
                                    jfdghsjdfhsdkljhkjsefhwoeutowuethdsjhfjksh
                                </Box>
                            </Box> */}

          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://defidungeon.io"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                defidungeon.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos-delay="1000"
        data-aos-duration="1000"
      >
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActiveTwo ? "info_btn active" : "info_btn"}
            onClick={toggleClassTwo}
          ></Button>
          <Box
            className={
              isActiveTwo ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_02"></Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClassTwo}></Button> */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/neko_text.png"
                    alt=""
                    className="neko_text"
                  />
                  <Box className="back_inn_p">
                    <Text>
                      Love Neko Anime?
                      <br />
                      Nekotoshi is your source for neko NFTs that are unique,
                      beautiful, and created by and for anime fans.
                    </Text>
                    <br />
                    <Text>
                      Enjoy the beauty, intrigue, and playfulness of our neko
                      anime NFTs. We love neko anime and we know you do too!
                      Each Nekotoshi NFT is created with passion and love for
                      neko anime.
                    </Text>
                    <br />
                    <Text>
                      Every Nekotoshi NFT will be absolutely unique, providing
                      an instant collectible that can be traded with friends,
                      sold to collectors, and used in our upcoming dress-up and
                      social games.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href="https://nekotoshi.io/"
                target="_blank"
                className="galla_img_links_first"
              >
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />{" "}
                nekotoshi.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
