import { App } from "../features/home";
import homeRoute from "../features/home/route";
import nft1Route from "../features/nft1/route";
import nft2Route from "../features/nft2/route";
import nft3Route from "../features/nft3/route";
import nft4Route from "../features/nft4/route";
import nft5Route from "../features/nft5/route";
import nft6Route from "../features/nft6/route";
import nft7Route from "../features/nft7/route";
import commonRoute from "../features/common/route";
import _ from "lodash";

const childRoutes = [homeRoute, commonRoute, nft1Route, nft2Route, nft3Route, nft4Route, nft5Route, nft6Route, nft7Route ];

const routes = [
  {
    path: "/",
    component: App,
    childRoutes: [
      ...childRoutes,
    ].filter((r) => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = "";
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
