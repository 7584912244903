import React, { useState } from "react";
import {
  Box,
  Button,
  GridItem,
  Heading,
  Image,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../redux/hooks";

export default function PoolContent({ index, npool }) {
  const [isActiveFive, setActiveFive] = useState(false);
  const toggleClassFive = () => {
    setActiveFive(!isActiveFive);
  };
  const [amount, setAmount] = useState(1);
  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  // const MP1 = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem colSpan={[12, 12, 6, 4]}>
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="2400"
        >
          <Button
            className={isActiveFive ? "info_btn active" : "info_btn"}
            onClick={toggleClassFive}
          ></Button>
          <Box
            className={
              isActiveFive ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box className="same_nine_ractangle_inn same_nine_ractangle_inn_05">
                <Box className="bahama_blure_box">
                  <SimpleGrid columns={12} columnGap={2} rowGap={0}>
                    <GridItem colSpan={[6, 6, 6, 6]}>
                      <Box className="text_box">
                        <Heading as="h6">
                          Earn BXP to
                          <br />
                          vacation on a <br />
                          private island
                        </Heading>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={[6, 6, 6, 6]}>
                      {" "}
                      <Image
                        src="/images/bahama_img_01.png"
                        alt=""
                        className="bahama_img_01"
                      />{" "}
                    </GridItem>
                    <GridItem colSpan={[12, 12, 12, 12]}>
                      <Heading as="h5" className="center_tect">
                        Backed by real Bahamas aragonite
                      </Heading>
                    </GridItem>
                    <GridItem colSpan={[6, 6, 6, 6]}>
                      {" "}
                      <Image
                        src="/images/bahama_img_02.png"
                        alt=""
                        className="bahama_img_01"
                      />{" "}
                    </GridItem>
                    <GridItem colSpan={[6, 6, 6, 6]}>
                      <Box className="text_box">
                        <Heading as="h6">
                          Build your Dubai
                          <br />
                          island in the
                          <br />
                          metaverse
                        </Heading>
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </Box>
                <Box className="bahama_blure_btm_box">
                  <SimpleGrid columns={12} columnGap={2} rowGap={0}>
                    <GridItem colSpan={[4, 4, 4, 4]}>
                      <Box className="text_blr_left">
                        <Heading as="h6">Presale</Heading>
                        <Heading as="h5">{/* {MP1[0]} */}/2000</Heading>
                      </Box>
                    </GridItem>
                    <GridItem colSpan={[8, 8, 8, 8]}>
                      <Box className="text_blr_right">
                        <Box className="text_blr_right_inn">
                          <Image
                            src="/images/eth_ic.svg"
                            alt=""
                            className="eth_ic"
                          />
                        {/*   <Text> {forMat(byDecimals(MP1[5], 18))}</Text> */}
                          <Text>ETH</Text>
                          {/*<Text>$0</Text>*/}
                        </Box>
                        <Box className="border_inpt_bx">
                          <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                          ></input>
                          <Button
                          disabled
                            onFocus={(event) => event.stopPropagation()}
                            // disabled={isDepositPending}
                            onClick={(event) => {
                         //     onDeposit(amount, MP1[5]) 
                            }}
                          >
                            Buy
                          </Button>
                        </Box>
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
            <Box className="flip-card-back">
              {/* <Button className='info_btn_back' onClick={toggleClassFive}></Button> */}
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src="/images/bahama_text_img.png"
                    alt=""
                    className="bahama_text_img"
                  />
                  <Box className="back_inn_p back_bahama_p">
                    <Text>
                      NFTs backed by the real world value of Bahamas aragonite
                      sand. Hold the NFTs to earn BXP, which can be used to
                      purchase unique and exotic Nahamas Experiences
                    </Text>
                    <Heading as="h6">Metaverse</Heading>
                    <Text>
                      A partnership with one of more metaverse projects is
                      coming soon. You can turn your NFTs into your own islands
                      in the metaverse!
                    </Text>
                    <Heading as="h6">Harvest</Heading>
                    <Text>Have your aragonite sand delivered to you!</Text>
                    <Box className="bold_p_bx">
                      <Text>
                        <b>Presale sale 2500@ 0.11 ETH</b>
                      </Text>
                      <Text>
                        <b>Public sale 10,000 @ 0.13 ETH</b>
                      </Text>
                    </Box>
                    <Text>
                      <b>Partner:</b> Pisa Carolina
                    </Text>
                    <Text>https://pisacarolina.com</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button as="a" href="https://bahamasexperience.io" target="_blank" className="galla_img_links_first">
                <Image
                  src="/images/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
                bahamasexperience.io
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic02.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
              <Button as="a" href="#" className="galla_img_links_second">
                {" "}
                <Image
                  src="/images/galla_img_links_ic03.svg"
                  alt=""
                  className="galla_img_links_ic0"
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
