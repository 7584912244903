export const nft4pool = [

  { 
    chainId: 3,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0x9aEce359d84C4a3aeA89cAc18F293262C254Ef9a',  // Golden ticket
    status: 'aktive'
  },
]