import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { renderIcon } from "@download/blockies";
import {
  Box,
  Button,
  useDisclosure, Container
} from "@chakra-ui/react";

export default function Header(props) {
  const { connected, address, connectWallet, disconnectWallet } = props;
  const canvasRef = useRef(null);
  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("right");

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 3)}..${address.slice(-3)}`);
    }
  }, [dataUrl, address, connected]);

  return (
    <Box className='header_main'>
            <Container maxW="container.xl">
              <Box className='head_top'>
                <img src='/images/header_logo_img.png' alt='' className='header_logo_img' />
              </Box>
              <Box className='cnnct_wllt_sc'>
                <Button className="cnnct_wllt"
                onClick={connected ? disconnectWallet : connectWallet}
                >
                  {connected ? (
    <>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    <span>  {shortAddress} </span>
    </>
  ) : (
    <>    <span>Connect Wallet</span></>
  )}


              
                </Button>
              </Box>
              
            </Container>
  </Box>
  );
}

{/* 
 */}