import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CircleBoxes2() {
  let { nft1pool } = useFetchPoolBalances();

  return (
    <>
      {nft1pool.map((npool, index) => (
          <PoolContent index={index} npool={npool} />
        ))}
    </>
  );
}

export default CircleBoxes2;
