import { VaultPage } from '.';


export default {
  path: "nft7",
  childRoutes: [
    { path: "nft7", component: VaultPage, isIndex: true },

  ],
};
