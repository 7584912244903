export const nft1pool = [

 { 
    chainId: 1,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0xEE3B022cf3BA066Fc0F5FB54fD021544e1C6DAF4',  // Golden ticket
    status: 'aktive'
  },
]