export const nft2pool = [

  { 
    chainId: 3,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0xe68e40eeEb0a86D9CC17a138Ff7DE88A7B19Dd9f',  // Golden ticket
    status: 'aktive'
  },
]