import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CircleBoxes5() {
  let { nft4pool } = useFetchPoolBalances();

  return (
    <>
      {nft4pool.map((npool, index) => (
          <PoolContent index={index} npool={npool} />
        ))}
    </>
  );
}

export default CircleBoxes5;
