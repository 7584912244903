export const nft6pool = [

  { 
    chainId: 3,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0xB537f93df03cfa93c5791a48653D256C4550038f',  // Golden ticket
    status: 'aktive'
  },
]