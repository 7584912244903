import React, { useEffect } from "react";
import CircleBoxes2 from "./sections/CircleBoxes2";

export default function VaultPage1() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);


  return (
    <>
            <CircleBoxes2 />
    </>
  );
}
