import React, { useEffect } from "react";
import CircleBoxes8 from "./sections/CircleBoxes8";
import { Box } from '@chakra-ui/react'

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);


  return (
    <>
            <CircleBoxes8 />
    </>
  );
}
