import { VaultPage1 } from '.';


export default {
  path: "nft1",
  childRoutes: [
    { path: "nft1", component: VaultPage1, isIndex: true },

  ],
};
