import { nft7pool } from "../../configure";

const tokens = {
  4: {},
  3: {},
  1: {}

};

nft7pool.map(({chainId, token, tokenAddress })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  nft7pool,
  tokens,
};

export default initialState;