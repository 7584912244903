import WalletConnectProvider from "@walletconnect/web3-provider";

export const getNetworkConnectors = () => {
  switch (process.env.REACT_APP_NETWORK_ID) {
    case "56":
      return {
        network: "binance",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "MetaMask",
              description: ("BrowserWallet"),
            },
          },
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://bsc-dataseed.binance.org/",
                56: "https://bsc-dataseed.binance.org/",
              },
            },
          },
        },
      };
    case "137":
      return {
        network: "matic",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "WalletConnect",
              description: ("BrowserWallet"),
            },
          },
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://rpc-mainnet.maticvigil.com",
                137: "https://rpc-mainnet.maticvigil.com",
              },
            },
          },
        },
      };
    default:
      return {};
  }
};
