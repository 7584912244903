import React, { useEffect, useState, useRef } from "react";
import { useConnectWallet } from '../home/redux/hooks';
import { renderIcon } from "@download/blockies";
import Landing from "pages/Landing";
import CircleBoxes3 from "features/nft2/sections/CircleBoxes3";

export default function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const canvasRef = useRef(null);
  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);
  const { connected, address  } = props;
  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current;

    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 3)}..${address.slice(-3)}`);
    }
  }, [dataUrl, address, connected]);

  return (
    <>
      <Landing />
    </>
  );
}
