import React from "react";
import FirstSc from "./FirstSc";
import AOS from "aos";
import { Box, Container, Heading, SimpleGrid, Text, Image, Button } from "@chakra-ui/react";
import Gallary1 from "./Gallary1";
import CircleBoxes2 from "features/nft1/sections/CircleBoxes2";
import CircleBoxes3 from "features/nft2/sections/CircleBoxes3";
import { VaultPage } from "features/nft2";
import { VaultPage1 } from "features/nft1";
import CircleBoxes4 from "features/nft3/sections/CircleBoxes4";
import CircleBoxes5 from "features/nft4/sections/CircleBoxes5";
import CircleBoxes6 from "features/nft5/sections/CircleBoxes6";
import CircleBoxes7 from "features/nft6/sections/CircleBoxes7";
import CircleBoxes8 from "features/nft7/sections/CircleBoxes8";
import { Element } from "react-scroll";

export default function Landing() {
  AOS.init();
  return (
    <>
      <div className="p_rltv">
        <div className="box_p">
          <FirstSc />
          <Element name="GallarySc">
            <Box className="portfolio_gallary_section">
              <Box
                className="gallary_sect"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                <Heading as="h4">Gallery</Heading>
                <Box className="bor_width"></Box>
                <Text>Projects I’ve worked on, NFTs for SALE! </Text>
              </Box>
              <Container maxW="container.xl">
                <SimpleGrid columns={12} columnGap={3} rowGap={4}>
                  <Gallary1 />
                  <CircleBoxes2 />
                  <CircleBoxes3 />
                  <CircleBoxes4 />
                  <CircleBoxes5 />
                  <CircleBoxes6 />
                  <CircleBoxes7 />
                  <CircleBoxes8 />
                 
                </SimpleGrid>
              </Container>
            </Box>
          </Element>
        </div>
        <div className="shadow_box"></div>
      </div>
    </>
  );
}
